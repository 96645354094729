import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";

@Injectable()
export class BlogService {
  private apiPrefix = '/public/blog';

  constructor(
    private api: ApiService,
  ) {
  }

  getCategories() {
    return this.api.get(`${this.apiPrefix}/categories`);
  }

  getCategoryWithSlug(slug: string) {
    return this.api.get(`${this.apiPrefix}/categories/${slug}`);
  }

  getArticles(qs = '') {
    return this.api.get(`${this.apiPrefix}/articles${qs}`);
  }

  getArticleWithSlug(slug: string) {
    return this.api.get(`${this.apiPrefix}/articles/${slug}`);
  }
}
